import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'clsx'

import i18n from '@src/i18n'
import Text from '@components/Text'
import Popup from '@components/Popup'
import { Button, ButtonProps } from './index'

export enum ConfirmType {
  delete = 'delete',
  common = 'common',
}

export interface ButtonWithConfirmationProps extends ButtonProps {
  text: string
  subText?: string | React.ReactNode
  reverse?: boolean
  confirmType?: ConfirmType | keyof typeof ConfirmType
  confirmText?: string
  rejectText?: string
  shouldConfirm?: boolean
}

export const ButtonWithConfirmation: React.FC<React.PropsWithChildren<ButtonWithConfirmationProps>> = ({
  text,
  subText,
  children,
  onClick,
  reverse = false,
  confirmType = ConfirmType.delete,
  confirmText = i18n.t('common:confirmButtonPopup.confirm'),
  rejectText = i18n.t('common:confirmButtonPopup.reject'),
  shouldConfirm = true,
  ...buttonProps
}) => {
  const { t } = useTranslation('common')

  const [isPopupVisible, setIsPopupVisible] = useState(false)

  const toggleVisibility = useCallback((e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault()
    e?.stopPropagation()
    setIsPopupVisible((st) => !st)
  }, [])

  const handleConfirm = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      toggleVisibility()
      onClick?.(e)
    },
    [onClick],
  )

  return (
    <>
      {isPopupVisible && (
        <Popup containerStyle="z-[150]" onClose={toggleVisibility}>
          <div className="flex flex-col mb-4 gap-2">
            <p className="font-medium border-b leading-8">
              <Text text={text} />
            </p>
            <p>{subText}</p>
          </div>
          <div
            className={cx('flex gap-2', {
              'justify-between': confirmType === ConfirmType.delete,
              'justify-end': confirmType === ConfirmType.common,
            })}
          >
            <Button
              color={confirmType === ConfirmType.common ? 'default' : reverse ? 'success' : 'cancel'}
              onClick={toggleVisibility}
              type={confirmType === ConfirmType.common ? 'outlined' : 'default'}
            >
              {rejectText || t('cancel')}
            </Button>
            <Button
              color={reverse ? 'cancel' : 'success'}
              onClick={handleConfirm}
              name={buttonProps.name}
              id={buttonProps.id}
            >
              {confirmText || t('confirm')}
            </Button>
          </div>
        </Popup>
      )}
      <Button {...buttonProps} onClick={shouldConfirm ? toggleVisibility : onClick}>
        {children}
      </Button>
    </>
  )
}

export default ButtonWithConfirmation
