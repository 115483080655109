import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useGetList, useCreate, useDelete, useGetOne, usePatch } from '../request'

import {
  AccountBulkAction,
  AccountListFilter,
  AccountListItem,
  AccountRequest,
  AccountResponse,
  CategoryListItem,
  CategoryRequest,
  CategoryResponse,
  CreateCategoriesRequest,
} from '@models/AccountingAccount'
import { AccountingKeys } from '../Keys'
import api from '@api/api'
import { generatePath } from 'react-router-dom'

const PATHS = {
  accounts: '/api/accounting/accounts',
  accountSync: '/api/accounting/organizations/:organizationId/sync-accounts',
  accountsBulkAction: '/api/accounting/accounts/bulk',
  accountCategories: '/api/accounting/categories',
  accountCategoriesBulk: '/api/accounting/categories/bulk',
}

// accounts
export const useGetAccounts = (filters: Partial<AccountListFilter>, enabled = true) => {
  const query = new URLSearchParams(JSON.parse(JSON.stringify(filters)))

  return useGetList<AccountListItem>([AccountingKeys.Accounts, query.toString()], PATHS.accounts, {
    query: {
      enabled: enabled && !!filters.organization,
      placeholderData: [],
      keepPreviousData: true,
    },
    axios: {
      params: filters,
    },
  })
}

export const useGetAccount = (id?: number | string) =>
  useGetOne<AccountResponse>([AccountingKeys.Accounts, id?.toString()], `${PATHS.accounts}/${id}`, {
    query: {
      enabled: !!id,
    },
  })

export const useCreateAccount = () => {
  const cache = useQueryClient()
  return useCreate<AccountRequest, AccountResponse>(PATHS.accounts, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.Accounts])
      },
    },
  })
}

export const useUpdateAccount = (id?: number | string) => {
  const cache = useQueryClient()
  return usePatch<Partial<AccountRequest>, AccountResponse>(`${PATHS.accounts}/${id}`, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.Accounts])
      },
    },
  })
}

export const useDeleteAccount = (id?: number | string) => {
  const cache = useQueryClient()
  return useDelete(`${PATHS.accounts}/${id}`, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.Accounts])
      },
    },
  })
}

export const useSyncAccount = (organizationId?: string | number | null) => {
  const cache = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      const res = await api.get(generatePath(PATHS.accountSync, { organizationId }))
      return res.data
    },
    onSuccess: () => {
      cache.invalidateQueries([AccountingKeys.Accounts])
    },
  })
}
export const useBulkAccountsUpdate = () => {
  const cache = useQueryClient()
  return usePatch<AccountBulkAction>(PATHS.accountsBulkAction, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.Accounts])
      },
    },
  })
}

// categories
export const useGetAccountCategories = (enabled = true) =>
  useGetList<CategoryListItem>([AccountingKeys.AccountCategories], PATHS.accountCategories, {
    query: {
      enabled,
      refetchOnMount: false,
    },
  })

export const useGetAccountCategory = (id?: number | string) =>
  useGetOne<CategoryResponse>([AccountingKeys.AccountCategories, id?.toString()], `${PATHS.accountCategories}/${id}`, {
    query: {
      staleTime: 1000 * 60 * 3,
      enabled: !!id,
    },
  })

export const useCreateAccountCategories = () => {
  const cache = useQueryClient()
  return useCreate<CreateCategoriesRequest, CategoryResponse>(PATHS.accountCategoriesBulk, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.AccountCategories])
      },
    },
  })
}

export const useUpdateAccountCategory = (id?: number | string) => {
  const cache = useQueryClient()
  return usePatch<CategoryRequest, CategoryResponse>(`${PATHS.accountCategories}/${id}`, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.AccountCategories])
      },
    },
  })
}

export const useDeleteAccountCategory = (id?: number | string) => {
  const cache = useQueryClient()
  return useDelete(`${PATHS.accountCategories}/${id}`, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.AccountCategories])
      },
    },
  })
}
