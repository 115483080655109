import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { IconChevronLeft, IconChevronRight, IconX } from '@tabler/icons-react'

import Button from '@components/Button'

import styles from './index.module.css'

import { NavigateDirection } from '@type/common'

interface PopupProps {
  title?: string
  id?: string
  onClose?: () => void
  className?: string | null
  containerStyle?: string | null
  closeButtonStyle?: string | null
  allowCloseOnEsc?: boolean
  allowCloseOnMissClick?: boolean

  onNavigate?(direction: NavigateDirection): void

  canNavigate?(direction: NavigateDirection): boolean
}

const Popup: React.FC<React.PropsWithChildren<PopupProps>> = ({
  children,
  title,
  className,
  id,
  containerStyle,
  closeButtonStyle,
  allowCloseOnEsc = true,
  allowCloseOnMissClick = true,
  onClose,
  onNavigate,
  canNavigate = () => false,
}) => {
  const [container, setContainer] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const el = document.getElementById('popup-container')

    if (el) {
      setContainer(el)
    }
  }, [])

  const handleClose: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.stopPropagation()
      if (onClose) {
        onClose()
      }
    },
    [onClose],
  )

  const stopPropagation: React.MouseEventHandler<HTMLElement> = useCallback((e) => {
    e.stopPropagation()
  }, [])

  const handleKeyDown = (e: KeyboardEvent) => {
    e.stopPropagation()
    if (e.key === 'Escape') {
      onClose?.()
    }
  }

  useEffect(() => {
    if (allowCloseOnEsc) {
      document.addEventListener('keydown', handleKeyDown)
    }
    document.body.style.overflow = 'hidden'

    return () => {
      if (allowCloseOnEsc) {
        document.removeEventListener('keydown', handleKeyDown)
      }
      document.body.style.overflow = 'auto'
    }
  }, [onClose])

  const disableNavigateRight = !canNavigate(NavigateDirection.right)
  const disableNavigateLeft = !canNavigate(NavigateDirection.left)

  return container
    ? ReactDOM.createPortal(
        <div
          className={twMerge(
            cx(
              'fixed z-[25] flex bg-gray-900 bg-opacity-60 w-full h-full text-black justify-center items-center overflow-auto',
              containerStyle,
            ),
          )}
          onClick={allowCloseOnMissClick ? handleClose : undefined}
          id={id ? `close-missclick-${id}` : 'close-missclick-popup'}
        >
          {onNavigate && (
            <Button
              color="white"
              onClick={(e) => {
                stopPropagation(e)
                if (!disableNavigateLeft) {
                  onNavigate(NavigateDirection.left)
                }
              }}
              className="w-14 h-14 rounded-[20px] border-none disabled:opacity-50 !pointer-events-auto hidden md:block"
              disabled={disableNavigateLeft}
            >
              <IconChevronLeft className="w-6 h-6" />
            </Button>
          )}
          <div
            className={twMerge(
              cx(
                'max-h-[90%] rounded-xl bg-white p-4 opacity-100 max-w-md w-full my-auto overflow-auto relative',
                styles.popup,
                className,
              ),
            )}
            onClick={stopPropagation}
          >
            {title && <h4 className="text-lg leading-6 font-semibold border-b mb-3 pb-2">{title}</h4>}
            <Button
              type="contained"
              id={id ? `close-${id}` : 'close-popup'}
              onClick={handleClose}
              className={cx('absolute right-2.5 top-2.5 z-50 p-0 min-h-0 h-auto', closeButtonStyle)}
            >
              <IconX className="h-5 w-5" />
            </Button>
            {children}
          </div>
          {onNavigate && (
            <Button
              color="white"
              onClick={(e) => {
                stopPropagation(e)
                if (!disableNavigateRight) {
                  onNavigate(NavigateDirection.right)
                }
              }}
              disabled={disableNavigateRight}
              className="w-14 h-14 rounded-[20px] border-none disabled:opacity-70 !pointer-events-auto hidden md:block"
            >
              <IconChevronRight className="w-6 h-6" />
            </Button>
          )}
        </div>,
        container,
      )
    : null
}

export default Popup
export { usePopupHandlers } from './usePopupHandler'
